import React, { useRef, useEffect, useState } from "react";
import LineStep from "../images/sedstart/Line-step.svg";

const steps = [
    {
        id: 1,
        title: "Step 1",
        description: "Create plain english steps and reuse them across any tests",
    },
    {
        id: 2,
        title: "Step 2",
        description: "Automate Steps using page object building blocks",
    },
    {
        id: 3,
        title: "Step 3",
        description: "Create static and dynamic suites to run multiple tests",
    },
    {
        id: 4,
        title: "Step 4",
        description: "Run across multiple environments using data profiles",
    },
];

const HorizontalScrollSteps = () => {
    const containerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        if (containerRef.current) {
            setScrollPosition(containerRef.current.scrollLeft);
        }
    };

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <>
            <div className="sedstart-steps">
                <div className="sedstart-steps-grid">
                    {steps?.map((step, id) => (
                        <div className="sedstart-steps-grid__item" key={id}>
                            <button className="step-title gap-05x">{step.title}</button>
                            <span className="step-desc">{step.description}</span>
                        </div>
                    ))}
                    <div className="progress-line flex flex-ai-c flex-jc-c">
                        <img src={LineStep} alt="" className="line-step-one" />
                        <img src={LineStep} alt="" className="line-step-two" />
                        {/* <img src={LineStep} alt="" className="line-step-three" /> */}
                        <div className="circle-one"></div>
                        <div className="circle-two"></div>
                        <div className="circle-three"></div>
                        <div className="circle-four"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HorizontalScrollSteps;
